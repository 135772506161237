import { DetectType } from '../enums'

const DEFAULT_DETECT_SETTINGS = {
  name: '',
  type: DetectType.DUMP,
  isActive: false,
  isEnableSound: false,
  sound: 1,
  alertTime: 1,
  pairList: 0,
  lastAssetList: [],
  minVolumePerHour: '1000',
  maxVolumePerHour: '',
  minVolumePerDay: '10000',
  maxVolumePerDay: '',
  priceInterval: 60,
  priceChange: '1',
  minTradesPerSec: '',
  maxTradesPerSec: '',
  volumePerSec: '',
  delta: []
}

const DEFAULT_DELTA_SETTINGS = {
  min: '1',
  max: '10'
}

export { DEFAULT_DETECT_SETTINGS, DEFAULT_DELTA_SETTINGS }
